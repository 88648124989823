/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


// onClientEntry happens first
// https://github.com/gatsbyjs/gatsby/issues/14012#issuecomment-492224526

exports.onClientEntry = () => {
	// Detect if it's a mobile device only (not tablet), and add a class to <body>
	// https://github.com/duskload/mobile-device-detect
	const { isChrome, isFirefox } = require( 'mobile-device-detect' );
	const body = document.querySelector( 'body' );
	const browserName = isChrome ? 'chrome' : isFirefox ? 'firefox' : null;

	if ( browserName ) {
		body.classList.add( `browser-${browserName}` );
	}
};


exports.onRouteUpdate = ( { location } ) => {
	const html = document.querySelector( 'html' );
	const rootPath = `${__PATH_PREFIX__}/`;

	if ( location.pathname === rootPath ) {
		html.className = 'is-home';
	} else {
		html.className = 'is-404';
	}
};
